import {IActiveUserAccount} from "../../slices/accountSlice";
import {User} from "firebase/auth";

export const transformUserResponse = async (
  userResponse: User
): Promise<IActiveUserAccount> => {
  return {
    token: await userResponse.getIdToken(),
    user: {
      name: userResponse.displayName || '',
      id: userResponse.uid,
      email: userResponse.email || ''
    }
  }
}
