import { rootApi } from '../rootApi'
import {IActiveUserAccount} from "../../slices/accountSlice";
import {closeModal} from "../../slices/modalSlice";

export interface INewUser {
  name: string
  lastname: string,
  email: string,
  phone: string,
  document: string
  documents: string[]
}

export interface IUpdateUser extends INewUser {
  documents: string[]
}

const userApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<IActiveUserAccount, INewUser>({
      query: ({ email, name, lastname, document, phone }) => ({
        url: () => `users`,
        data: {
          email, name, lastname, document, phone
        },
        method: 'POST',
        successMessage: {
          type: 'success',
          title: 'Acción exitosa',
          value: 'Has creado el usuario con éxito',
        },
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
    updateUsers: builder.mutation<IActiveUserAccount, {user: IUpdateUser, userId: string}>({
      query: ({ user, userId  }) => ({
        url: () => `users`,
        method: 'PUT',
        entryId: userId,
        data: user,
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
  }),
  overrideExisting: false,
})

export const {useCreateUserMutation, useUpdateUsersMutation} = userApi
