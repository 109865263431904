import * as React from "react";
import {getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../store/storeHooks/hooks";
import '../config/fireBaseConfig'
import {logout, setActiveUser} from "../store/slices/accountSlice";
import {transformUserResponse} from "../store/api/apiResponses/userApiResponse";
import useAppLocation from "./useAppLocation";
import {APP_ROUTES, IRoute, PRIVATE_ROUTES, PUBLIC_ROUTES} from "../constants/appRoutes";
import {getLocationData} from "../utils/LocationUtils";

const useAuthentication = () => {
    let navigate = useNavigate();
    const {activePath} = useAppLocation()
    const auth = getAuth();
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.account.activeUserAccount)
    const accessValidation = (routeValidation?: IRoute | null, haveSession?: 'VALID' | 'INVALID') => {
        if (routeValidation) {
            const invalidSession = (haveSession && haveSession === 'INVALID') || (!haveSession && !user.token)
            if (invalidSession && !PUBLIC_ROUTES.includes(routeValidation.path)) {
                navigate(APP_ROUTES.LOGIN.path)
            } else if (!invalidSession && !PRIVATE_ROUTES.includes(routeValidation.path)) {
                navigate(APP_ROUTES.HOME.path)
            }
        }
    }
    const login = (userData: {user: string, password: string}) => {
        signInWithEmailAndPassword(auth, userData.user, userData.password)
            .then(async (result: any) => {
                const user = await transformUserResponse(result.user)
                dispatch(setActiveUser(user))
            })
            .catch((error: any) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('a:::::', errorCode)
                console.log('a:::::', errorMessage)
            });
    }
    const logoutAction = () => {
        console.log('inicio logout:_-------------')
        signOut(auth).then(() => {
            console.log('terminó logout:_-------------', auth)
            dispatch(logout())
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
            console.log('error', error)
        });
    }
    const getUser = () => {
        onAuthStateChanged(auth, async (userResponse) => {
            const currentLocation = getLocationData()
            if (userResponse) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const user = await transformUserResponse(userResponse)
                dispatch(setActiveUser(user))
                accessValidation(currentLocation, 'VALID')
            } else {
                // User is signed out
                accessValidation(currentLocation, 'INVALID')
            }
        });
    }
    React.useEffect(() => {
        getUser()
    }, [])
    React.useEffect(() => {
        if (activePath) {
            accessValidation(activePath)
        }
    }, [activePath])
    return {user, login, logoutAction}
}

export {
    useAuthentication
}