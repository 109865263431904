import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from './apiUtils/requestUtil'
import { HOST } from '../../config/config'

export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST,
  }),
  tagTypes: [],
  endpoints: () => ({}),
})