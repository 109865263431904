import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAuthReducer {
  updateUserInformationError: { [name: string]: string }
  loginErrors: { [name: string]: string }
  signInErrors: { [name: string]: string }
  invoiceErrors: { [name: string]: string }
  resetPasswordErrors: { [name: string]: string }
  recoveryPasswordErrors: { [name: string]: string }
  isSuccessSendRecoveryPass: boolean
  isSuccessResetPass: boolean
}

export const initialState: IAuthReducer = {
  updateUserInformationError: {},
  loginErrors: {},
  signInErrors: {},
  invoiceErrors: {},
  recoveryPasswordErrors: {},
  resetPasswordErrors: {},
  isSuccessSendRecoveryPass: false,
  isSuccessResetPass: false,
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.loginErrors = action.payload
    },
    signInSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.signInErrors = action.payload
    },
    invoiceSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.invoiceErrors = action.payload
    },
    recoveryPasswordSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.recoveryPasswordErrors = action.payload
    },
    resetPasswordSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.resetPasswordErrors = action.payload
    },
    recoveryPasswordSetSuccess: (state) => {
      state.isSuccessSendRecoveryPass = true
    },
    recoveryPasswordResetSuccess: (state) => {
      state.isSuccessSendRecoveryPass = false
    },
    resetPasswordSetSuccess: (state) => {
      state.isSuccessResetPass = true
    },
    resetPasswordResetSuccess: (state) => {
      state.isSuccessResetPass = false
    },
    updateUserInformationSetErrors: (
      state,
      action: PayloadAction<{ [name: string]: string }>
    ) => {
      state.updateUserInformationError = action.payload
    },
  },
})

export const {
  loginSetErrors,
  recoveryPasswordResetSuccess,
  recoveryPasswordSetErrors,
  recoveryPasswordSetSuccess,
  resetPasswordResetSuccess,
  resetPasswordSetErrors,
  resetPasswordSetSuccess,
  signInSetErrors,
  updateUserInformationSetErrors,
  invoiceSetErrors,
} = authSlice.actions

export default authSlice.reducer
