import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import {useAppSelector} from "../../store/storeHooks/hooks";

const LoaderView = () => {
  const loadingList = useAppSelector((state) => state.loader.loader)
  return (
    <CSSTransition
      in={!!loadingList.length}
      timeout={300}
      classNames="alert"
      unmountOnExit={true}
    >
      <div className="loader-view">
        <div className="container">
          <div className="shape shape-1" />
          <div className="shape shape-2" />
          <div className="shape shape-3" />
          <div className="shape shape-4" />
        </div>
      </div>
    </CSSTransition>
  )
}

export default React.memo(LoaderView)
