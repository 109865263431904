import * as React from 'react'
import { CODE_HTTP_RESPONSE } from '../constants/codeHttpResponse'

interface IImageSvgInterface {
  icon: string
  // eslint-disable-next-line
  style?: object;
  className?: string
}

/* istanbul ignore next */
export const SvgImport = (props: IImageSvgInterface) => {
  const { icon, className, style } = props
  const [isLoadRef, changeIsLoadRef] = React.useState(false)
  // eslint-disable-next-line
  const iconRef: any = React.useRef()
  const loadImage = () =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line
      const xhr = new XMLHttpRequest();
      xhr.open('GET', icon)
      xhr.overrideMimeType('image/svg+xml')
      xhr.send('')
      xhr.onload = () => {
        if (
          xhr.status >= CODE_HTTP_RESPONSE.SUCCESS_200 &&
          xhr.status < CODE_HTTP_RESPONSE.SUCCESS_300
        ) {
          resolve(xhr.responseXML?.documentElement)
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          })
        }
      }
      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        })
      }
    })

  const getIcon = async () => {
    const icon = await loadImage()
    if (iconRef?.current) {
      iconRef.current.append(icon)
    }
  }

  React.useEffect(() => {
    changeIsLoadRef(true)
  }, [])

  React.useEffect(() => {
    if (isLoadRef && !iconRef.current?.child) {
      getIcon()
    }
  }, [isLoadRef])

  if (!props.icon) {
    return <div />
  }

  return (
    <div
      ref={iconRef}
      style={style || {}}
      className={`svg-icon ${className || ''}`}
    />
  )
}

export const getFileName = (fileName: string) => {
  if (!fileName) {
    return ''
  }
  const stringIndex = fileName.lastIndexOf('/')
  return fileName.substring(stringIndex + 1)
}

export const getFileExtension = (fileName: string) => {
  const fileNameSubstring = getFileName(fileName)
  const fileExtensionIndex = fileNameSubstring
    ? fileNameSubstring.lastIndexOf('.')
    : 0
  return fileNameSubstring
    ? fileNameSubstring.substring(fileExtensionIndex + 1)
    : ''
}
