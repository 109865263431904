import * as React from 'react'

import {IAlertsLanguage} from "../languages/es_EC/alert";
import {IAlertsObjectType, removeAlertItem} from "../../store/slices/alertsSlice";
import {ERROR_API_RESPONSE} from "../../constants/errorApiResponse";
import getLabels from "../languages";
import {SvgImport} from "../../utils/ImageUtils";
import {useAppDispatch, useAppSelector} from "../../store/storeHooks/hooks";
import checkIcon from '../../assets/images/check-icon.svg'
import warningIcon from '../../assets/images/warning-icon.svg'
import closeIcon from '../../assets/images/close.svg'

const AlertsView = () => {
  const alertList = useAppSelector((state) => state.alerts.alertList)
  const labels: IAlertsLanguage = useAppSelector((state) =>
    getLabels('alert', 'es_EC')
  )
  const dispatch = useAppDispatch()
  const closeAlert = (value: any) => {
    dispatch(removeAlertItem(value))
  }
  const getAlertIcon = (type: string, key: number) => {
    let icon = null
    switch (type) {
      case 'success':
        icon = checkIcon
        break
      case 'info':
        icon = checkIcon
        break
      default:
        icon = warningIcon
        break
    }
    return (
      <SvgImport
        key={key}
        icon={icon}
        className={`icon-area icon-24x fill-${type || 'warning'}`}
      />
    )
  }

  const getAlertTitle = (item: IAlertsObjectType) => {
    if (item.title) {
      return item.title
    }
    const { SUCCESS_CREATE_TITLE, SUCCESS_SAVE_TITLE, WARNING_TITLE } = labels
    switch (item.type) {
      case 'success':
        return SUCCESS_CREATE_TITLE
      case 'info':
        return SUCCESS_SAVE_TITLE
      default:
        return WARNING_TITLE
    }
  }

  const getAlertDescription = (item: IAlertsObjectType) => {
    if (ERROR_API_RESPONSE[item?.value]) {
      return ERROR_API_RESPONSE[item.value]
    }
    if (item?.value) {
      return item.value
    }
    const {
      SUCCESS_CREATE_DESCRIPTION,
      SUCCESS_SAVE_DESCRIPTION,
      WARNING_DESCRIPTION,
    } = labels
    switch (item.type) {
      case 'success':
        return SUCCESS_CREATE_DESCRIPTION
      case 'info':
        return SUCCESS_SAVE_DESCRIPTION
      default:
        return WARNING_DESCRIPTION
    }
  }
  return (
    <div className="alert-view">
      {alertList.map((item, key) => {
        if (!item) {
          return null
        }
        return (
          item && (
            <div key={key}>
              <div className={`alert-item flex-row flex-middle`} key={key}>
                {getAlertIcon(item.type, key)}
                <div className="m-l m-r left-align width-100">
                  <div className="secondary-font strong size-default text-uppercase alert-title">
                    {getAlertTitle(item)}
                  </div>
                  <div
                    className="secondary-font size-small wrap"
                    dangerouslySetInnerHTML={{
                      __html: getAlertDescription(item),
                    }}
                  />
                </div>
                <div onClick={() => closeAlert(item)} className="icon-16">
                  <SvgImport
                    icon={closeIcon}
                    className="close-icon-button fill-secondary icon-16x"
                  />
                </div>
              </div>
            </div>
          )
        )
      })}
    </div>
  )
}

export default AlertsView
