import React from "react";

import {ModalLayoutComponent} from "../components/modals/ModalLayoutComponent";
import {InputComponent} from "../components/global/InputComponent";
import {SvgImport} from "../utils/ImageUtils";
import userIcon from '../assets/images/user.svg'
import identificationIcon from '../assets/images/identification.svg'
import emailIcon from '../assets/images/email.svg'
import phoneIcon from '../assets/images/phone.svg'
import {validateValues} from "../utils/ValidationUtils";
import {useCreateUserMutation, useUpdateUsersMutation} from "../store/api/services/userApi";
import {IUserLocalUser} from "../store/slices/usersSlice";

interface IUserModal {
    directProps?: {
        user?: IUserLocalUser
    }
}

const UserModal = (props: IUserModal) => {
    const {directProps} = props
    const [userData, handleUserData] = React.useState({
        name: '',
        lastname: '',
        email: '',
        document: '',
        phone: ''
    })
    const [userId, handleUserId] = React.useState<string | null>(null)
    const [userDocuments, handleUserDocuments] = React.useState<string[]>([])
    const [createUser] = useCreateUserMutation()
    const [updateUser] = useUpdateUsersMutation()
    const [userErrors, setUserErrors] = React.useState<any>({})
    const handleUser = () => {
        const requireFields = [
            {key: 'name', message: 'El campo nombre es requerido', type: 'NOT_EMPTY'},
            {key: 'lastname', message: 'El campo apellido es requerido', type: 'NOT_EMPTY'},
            {key: 'email', message: 'El campo email es inválido', type: 'VALID_EMAIL'},
            {key: 'email', message: 'El campo email es requerido', type: 'NOT_EMPTY'},
            {key: 'phone', message: 'El campo teléfono es inválido', type: 'MIN_LENGTH', condition: 10},
            {key: 'phone', message: 'El campo teléfono es requerido', type: 'NOT_EMPTY'},
            {key: 'document', message: 'El campo documente es inválido', type: 'MIN_LENGTH', condition: 10},
            {key: 'document', message: 'El campo documente es requerido', type: 'NOT_EMPTY'},
        ]
        const result = validateValues(userData, requireFields as any)
        if (result.haveErrors){
            setUserErrors(result.errors)
        } else {
            if (userId) {
                updateUser({
                    user: {
                        ...userData,
                        documents: userDocuments
                    },
                    userId
                })
            } else {
                createUser({
                    ...userData,
                    documents: userDocuments
                })
            }
        }
    }
    const handleChange = (value: string | boolean, key: string) => {
        handleUserData({...userData, [key]: value})
        const newErrors = {...userErrors}
        delete newErrors[key]
        setUserErrors(newErrors)
    }
    React.useEffect(() => {
        if (directProps?.user) {
            handleUserId(directProps.user.id)
            handleUserDocuments(directProps.user.documents || [])
            handleUserData({
                phone: directProps.user.phone,
                email: directProps.user.email,
                document: directProps.user.document,
                lastname: directProps.user.lastname,
                name: directProps.user.name,
            })
        }
    }, [])
    return <ModalLayoutComponent
        title={userId ? "Editar usuario" : "Crear usuario"}
        successAction={handleUser}
        successActionText={userId ? "editar usuario" : "crear usuario"}
        closeOnCancel={true}
    >
        <div className="p-l-s p-r-s">
            <div className="container container-flex">
                <div className="row">
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="name"
                                value={userData.name}
                                onChangeValue={handleChange}
                                label="Nombres"
                                placeholder="Ejm: Juan Alejandro"
                                icon={<SvgImport icon={userIcon} className="icon-16x" />}
                                error={userErrors.name}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="lastname"
                                value={userData.lastname}
                                onChangeValue={handleChange}
                                label="Apellidos"
                                placeholder="Ejm: Cuenca Suarez"
                                error={userErrors.lastname}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="document"
                                value={userData.document}
                                icon={<SvgImport icon={identificationIcon} className="icon-16x" />}
                                onChangeValue={handleChange}
                                label="C.I./Pasaporte"
                                placeholder="Ejm: 1100110030"
                                error={userErrors.document}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="email"
                                value={userData.email}
                                onChangeValue={handleChange}
                                label="Email"
                                placeholder="Ejm: dana@wmrentacar.com"
                                icon={<SvgImport icon={emailIcon} className="icon-16x" />}
                                error={userErrors.email}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="phone"
                                value={userData.phone}
                                onChangeValue={handleChange}
                                label="Teléfono"
                                placeholder="Ejm: 0999123234"
                                icon={<SvgImport icon={phoneIcon} className="icon-16x" />}
                                error={userErrors.phone}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalLayoutComponent>
}

export default UserModal