import { rootApi } from '../rootApi'
import {IActiveUserAccount} from "../../slices/accountSlice";
import {closeModal} from "../../slices/modalSlice";

export interface IUserData {
  id: string
  name: string
  phone: string
  lastname: string
  document: string
  email: string
}

export interface IDocumentResponse {
  user: IUserData
  createAt: string
  contractDate: string
  city: string
  rentFromToDate: {
    startDate: string
    endDate: string
    key: string
  }
}

export interface IDocumentMetaResponse {
  extraDriverNames: string
  extraDriverDocument: string
  vehicleBrand: string
  vehicleModel: string
  vehicleMotor: string
  vehicleChassis: string
  vehiclePlate: string
  vehicleColor: string
  vehicleYear: string
  dailyPrice: string
  totalPrice: string
  controlTravel: boolean
  warranty: string
  warrantyType: string
  checkNumber: string
  checkBank: string
  checkBankAccount: string
  restrictionDay: string
  hideSignature: boolean
  signerName: string
  signerDocument: string
  signerId: string
}

const documentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<IActiveUserAccount, {document: IDocumentResponse, documentId: string}>({
      query: ({ document, documentId }) => ({
        url: () => `documents`,
        data: {
          ...document
        },
        entryId: documentId,
        method: 'POST',
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
    createDocumentMeta: builder.mutation<IActiveUserAccount, {documentMeta: IDocumentMetaResponse, documentId: string}>({
      query: ({ documentMeta, documentId }) => ({
        url: () => `documentsmeta`,
        data: {
          ...documentMeta
        },
        entryId: documentId,
        method: 'POST',
        successMessage: {
          type: 'success',
          title: 'Acción exitosa',
          value: 'Has creado el documento con éxito',
        },
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
    updateDocument: builder.mutation<IActiveUserAccount, {document: IDocumentResponse, documentId: string}>({
      query: ({ document, documentId }) => ({
        url: () => `documents`,
        data: {
          ...document
        },
        entryId: documentId,
        method: 'PUT',
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
    updateDocumentMeta: builder.mutation<IActiveUserAccount, {documentMeta: IDocumentMetaResponse, documentId: string}>({
      query: ({ documentMeta, documentId }) => ({
        url: () => `documentsmeta`,
        data: {
          ...documentMeta
        },
        entryId: documentId,
        method: 'PUT',
        successMessage: {
          type: 'success',
          title: 'Acción exitosa',
          value: 'Has creado el documento con éxito',
        },
        extraSuccessActions: [
          () => closeModal()
        ]
      }),
    }),
  }),
  overrideExisting: false,
})

export const {useCreateDocumentMutation, useCreateDocumentMetaMutation, useUpdateDocumentMetaMutation, useUpdateDocumentMutation} = documentApi
