import {doc, onSnapshot} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import { setSubsidiaries } from "../store/slices/accountSlice";

export const snapShotAccountSubscription = (dispatch: any, userId: string) => {
    const accountDocRef = doc(firestoreApp, 'account', userId);
    return onSnapshot(accountDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            dispatch(setSubsidiaries(data as any));
        } else {
            dispatch(setSubsidiaries({signers: []}));
        }
    })
}