import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILoaderReducer {
  loader: string[]
}

const initialState: ILoaderReducer = {
  loader: [],
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    addLoaderItem: (state, action: PayloadAction<string>) => {
      state.loader = [...state.loader, action.payload]
    },
    removeLoaderItem: (state, action: PayloadAction<string>) => {
      state.loader = state.loader.filter((item) => item !== action.payload)
    },
  },
})

export const { addLoaderItem, removeLoaderItem } = loaderSlice.actions

export default loaderSlice.reducer
