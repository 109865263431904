import login from './alerts/login'
import signin from './alerts/signin'
import recoveryPassword from './alerts/recoveryPassword'
import resetPassword from './alerts/resetPassword'

export interface IApiErrorsLanguage {
  ERROR_404: string
  ERROR_500: string
  ERROR_401: string
}

const apiErrors: IApiErrorsLanguage = {
  ...login,
  ...signin,
  ...recoveryPassword,
  ...resetPassword,
  ERROR_404: 'Solicitud Inválida',
  ERROR_500: 'Error interno',
  ERROR_401: 'No tiene permisos para esta petición',
}

export default apiErrors
