import { ThunkDispatch } from 'redux-thunk'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import { PayloadAction } from '@reduxjs/toolkit'
import { doc, setDoc, updateDoc } from "firebase/firestore";

import { handleErrors, handleLoader, IResponseAction } from './apiEventsHandler'
import { RootState } from '../../store'
import {
  addAlertItem,
  removeAlertItem,
  IAlertsObjectType,
} from '../../slices/alertsSlice'
import {firestoreApp} from "../../../config/fireBaseConfig";
import {getRandomId} from "../../../utils/StringUtils";

export interface BaseQueryApi {
  signal: AbortSignal
  dispatch: ThunkDispatch<any, any, any>
  getState: () => unknown
}

export interface BaseQueryArgs {
  url: (state: RootState) => string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  extraErrorAction?: (response: {
    [name: string]: string
  }) => PayloadAction<any>
  hideError?: boolean
  referenceErrorsKeys?: { [name: string]: string }
  hideLoader?: boolean
  extraSuccessActions?: IResponseAction[]
  successMessage?: IAlertsObjectType
  setFormErrorAction?: (data: any) => any
  entryId?: string
}

export const axiosBaseQuery =
  (
    { baseUrl, customToken }: { baseUrl: string; customToken?: string } = {
      baseUrl: '',
    }
  ): BaseQueryFn<BaseQueryArgs, unknown, unknown> =>
  async (
    {
      url,
      method,
      data,
      extraErrorAction,
      hideError,
      referenceErrorsKeys,
      hideLoader = false,
      extraSuccessActions,
      successMessage,
      setFormErrorAction,
      entryId
    }: BaseQueryArgs,
    api: BaseQueryApi
  ) => {
    const state = api.getState() as RootState
    const token = customToken || state.account.activeUserAccount.token
    const urlPath = url(state)
    const requestReference = `${new Date().getTime()}/${urlPath}-${method}`
    const headers: any = {}
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    try {
      handleLoader(requestReference, false, hideLoader, api.dispatch)
      let unicId = null
      let result: any = null;
      if (method === 'POST') {
        unicId = getRandomId()
        const fireStoreCreatePath = `${urlPath}${unicId || entryId ? `/${entryId || unicId}` : ''}`
        const fireStoreCreateDocumentReference = doc(firestoreApp, fireStoreCreatePath)
        // set document overwrite all document in path
        // use setDoc if you need automatic id and not need entryId || unicId
        await setDoc(fireStoreCreateDocumentReference, data).then(async (response: any) => {
          console.log('response', response)
          result = {
            type: 'SUCCESS',
            data: response || {success: true}
          }
        }).catch((e: any) => {
          result = {
            type: 'ERROR',
            error: {
              code: e.code,
              message: e.message
            }
          }
        })
      } else {
        const fireStoreUpdatePath = `${urlPath}/${entryId}`
        const fireStoreUpdateDocumentReference = doc(firestoreApp, fireStoreUpdatePath)
        // updateDoc update only send data on document
        await updateDoc(fireStoreUpdateDocumentReference, data).then(async (response: any) => {
          result = {
            type: 'SUCCESS',
            data: response || {success: true}
          }
        }).catch((e: any) => {
          result = {
            type: 'ERROR',
            error: {
              code: e.code,
              message: e.message
            }
          }
          console.log('error-u:::::::::::', e)
        })
      }
      // @ts-ignore
      handleLoader(requestReference, true, hideLoader, api.dispatch)
      if (result.type === 'ERROR') {
        handleErrors(
            result.error,
            data,
            'es_EC',
            !!hideError,
            referenceErrorsKeys,
            api.dispatch,
            setFormErrorAction,
            extraErrorAction,
        )
        return {
          error: { status: result.error.code, data: result.error.message },
        }
      }
      if (extraSuccessActions) {
        extraSuccessActions?.map((action) => {
          api.dispatch(action(result))
        })
      }
      const diplayTime = 5000
      if (successMessage) {
        api.dispatch(addAlertItem(successMessage))
        setTimeout(() => {
          api.dispatch(removeAlertItem(successMessage))
        }, diplayTime)
      }
      return { data: result.data || {} }
    } catch (axiosError) {
      handleLoader(requestReference, true, hideLoader, api.dispatch)
      const err = axiosError as AxiosError
      handleErrors(
        err,
        data,
        'es_EC',
        !!hideError,
        referenceErrorsKeys,
        api.dispatch,
        setFormErrorAction,
        extraErrorAction,
      )
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }
