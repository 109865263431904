import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import {IUser, IUserLocalUser, setUsers} from "../store/slices/usersSlice";

const userQuery = query(
    collection(firestoreApp, 'users'),
    orderBy('name')
)

export const snapShotUserSubscription = (dispatch: any) => onSnapshot(userQuery, (snapshot) => {
    const usersResponse: IUserLocalUser[] = []
    snapshot.forEach((doc) => {
        const userDetails = doc.data() as IUser
        usersResponse.push({
            id: doc.id,
            ...userDetails
        })
    });
    dispatch(setUsers(usersResponse))
})
