import { IAlertsLanguage } from '../es_EC/alert'

const alert: IAlertsLanguage = {
  SUCCESS_CREATE_DESCRIPTION: 'La información ha sido creada de manera exitosa',
  SUCCESS_CREATE_TITLE: 'La información se ha creado con éxito',
  SUCCESS_SAVE_DESCRIPTION: 'La información se ha actualizado con éxito',
  SUCCESS_SAVE_TITLE: 'Información actualizada con éxito',
  WARNING_DESCRIPTION: 'Existió un error en el requerimiento',
  WARNING_TITLE: '¡Atención!',
}

export default alert
