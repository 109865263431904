import {doc, onSnapshot} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import {IDocumentMetaResponse} from "../store/api/services/documentsApi";

const firestoreDocumentMetaReference = (id: string) => doc(firestoreApp, `documentsmeta/${id}`)

export const snapShotDocumentMetaSubscription = (documentId: string, onLoadAction: (document: IDocumentMetaResponse) => void) => onSnapshot(
    firestoreDocumentMetaReference(documentId),
    (docSnapshot) => {
    const documentMetaResponse = docSnapshot?.data() as IDocumentMetaResponse
    onLoadAction(documentMetaResponse)
})
