import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { CODE_HTTP_RESPONSE } from '../../../constants/codeHttpResponse'
import getLabels from "../../../components/languages";
import {addLoaderItem, removeLoaderItem} from "../../slices/loaderSlice";
import {addAlertItem, addAlertItemArray, removeAlertItem, removeAlertItemArray} from "../../slices/alertsSlice";
import {IApiErrorsLanguage} from "../../../components/languages/es_EC/apiErrors";

const disaptchErrorMessage = (
  errorStatus: number,
  errors: string[],
  apiErrors: IApiErrorsLanguage,
  isRemove: boolean,
  haveActionErrors: boolean,
  dispatch: any
) => {
  const errorAction = isRemove ? removeAlertItem : addAlertItem
  const errorActionArray = isRemove ? removeAlertItemArray : addAlertItemArray
  switch (errorStatus) {
    case CODE_HTTP_RESPONSE.ERROR_404:
      return errorAction({
        title: apiErrors.ERROR_404,
        type: 'warning',
        value: '',
      })
    case CODE_HTTP_RESPONSE.ERROR_401:
    case CODE_HTTP_RESPONSE.ERROR_403:
      return dispatch(
        errorAction({
          title: apiErrors.ERROR_401,
          type: 'warning',
          value: '',
        })
      )
    case CODE_HTTP_RESPONSE.ERROR_500:
      return dispatch(
        errorAction({
          title: apiErrors.ERROR_500,
          type: 'danger',
          value: '',
        })
      )
    default:
      if (errors.length) {
        return dispatch(
          errorActionArray(
            errors.map((itemError) => ({
              type: 'warning',
              value: itemError,
            }))
          )
        )
      } else if (haveActionErrors) {
        return null
      }
      return dispatch(
        errorAction({
          title: 'Api Error',
          type: 'danger',
          value: '',
        })
      )
  }
}

const buildAlertMessages = (
  errors: AxiosError,
  apiErrorsLabels: IApiErrorsLanguage,
  body: any,
  buildErrors: { [name: string]: string }
) => {
  const errorForms = {}
  const errorList: any = []
  // @ts-ignore
  const messages = errors?.response?.data?.error?.message || errors?.message
  // @ts-ignore
  const messagesData = errors?.response?.data?.error?.details?.errors
  let messageValue = []
  if (Array.isArray(messagesData)) {
    messageValue = messagesData
  }

  if (!messageValue.length && messages) {
    messageValue = [
      {
        message: messages,
        path: [],
      },
    ]
  }

  if (messageValue.length) {
    // eslint-disable-next-line
    messageValue.forEach((message: any) => {
      // eslint-disable-next-line
      const messageId = message.path[0] || ''
      let includeInForm = false
      const errorMessage =
      // @ts-ignore
        apiErrorsLabels[message.message] || message.message || ''
      Object.keys(buildErrors || body).forEach((bodyKey) => {
        if (messageId.includes(buildErrors ? buildErrors[bodyKey] : bodyKey)) {
          includeInForm = true
          // @ts-ignore
          errorForms[bodyKey] = errorMessage
        }
      })
      if (!includeInForm && errorMessage) {
        errorList.push(errorMessage)
      }
    })
  }
  return {
    errorForms,
    errorList,
  }
}

export const handleLoader = (
  ref: string,
  isRemove: boolean,
  hideLoader: boolean,
  dispatch: any
) => {
  if (hideLoader) {
    return null
  }
  if (isRemove) {
    dispatch(removeLoaderItem(ref))
  } else {
    dispatch(addLoaderItem(ref))
  }
}

export type IResponseAction = (response: any) => PayloadAction<any>

export const handleErrors = (
  errors: AxiosError,
  body: any,
  lang: string,
  hideError: boolean,
  referenceErrorsKeys: { [name: string]: string } = {},
  dispatch: any,
  setFormErrorAction: any,
  extraErrorsAction?: IResponseAction
) => {
  const apiErrorsLabels: IApiErrorsLanguage = getLabels('apiErrors', lang)
  if (hideError) {
    return null
  }
  const buildingErrors = buildAlertMessages(
    errors,
    apiErrorsLabels,
    body || {},
    referenceErrorsKeys
  )
  const haveCustomErrorsForms = !!(
    Object.keys(buildingErrors.errorForms).length && extraErrorsAction
  )
  if (setFormErrorAction) {
    dispatch(setFormErrorAction(buildingErrors.errorForms))
  }
  if (extraErrorsAction) {
    dispatch(extraErrorsAction(buildingErrors.errorForms))
  }
  disaptchErrorMessage(
    // @ts-ignore
    errors?.response?.status,
    buildingErrors.errorList,
    apiErrorsLabels,
    false,
    haveCustomErrorsForms,
    dispatch
  )

  const ALERT_ACTIVE_TIME = 5000

  setTimeout(() => {
    disaptchErrorMessage(
      // @ts-ignore
      errors?.response?.status,
      buildingErrors.errorList,
      apiErrorsLabels,
      true,
      haveCustomErrorsForms,
      dispatch
    )
  }, ALERT_ACTIVE_TIME)
}
