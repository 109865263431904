import {APP_NAVIGATION} from "../../constants/appRoutes";
import {SvgImport} from "../../utils/ImageUtils";
import {Link} from "react-router-dom";
import useAppLocation from "../../hooks/useAppLocation";
import {useAuthentication} from "../../hooks/authenticationHooks";

const AsideNavigation = () => {
    const {activePath} = useAppLocation()
    const {logoutAction} = useAuthentication()
    const handleAction = (action: string) => {
        if (action === 'logout') {
            logoutAction()
        }
    }
    return <div className="aside-navigation flex-column">
        <nav>
            <ul>
                {APP_NAVIGATION.map((navItem, key) => {
                    const activePathParams = !activePath?.action && activePath?.pathWithParams && activePath?.pathWithParams === navItem.pathWithParams
                    const activePathWOParams = !activePath?.action && !activePath?.pathWithParams && activePath?.path === navItem.path
                    const isActive =  activePathParams || activePathWOParams

                    const content = <span className={`nav-item flex-column height-100 width-100 flex-center flex-middle ${isActive && 'active-nav'}`}>
                                <SvgImport icon={navItem.icon as any} className={`icon-24x ${isActive ? 'fill-primary' : 'fill-white'}`} />
                                <span className={`btn-text text-uppercase p-t-s ${isActive ? 'text-white strong' : 'text-gray text-lighten-4'}`}>
                                {navItem.name}
                            </span>
                            </span>

                    return <li key={key}>
                        {!navItem.action && navItem.path
                            ? <Link to={navItem.path}>{content}</Link>
                            : <a onClick={() => handleAction(navItem?.action || '')}>{content}</a>
                        }
                    </li>
                })}
            </ul>
        </nav>
    </div>
}

export default AsideNavigation