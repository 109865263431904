export const DAYS = {
    MONDAY: {
        id: 'MONDAY',
        label: 'Lunes'
    },
    TUESDAY: {
        id: 'TUESDAY',
        label: 'Martes'
    },
    WEDNESDAY: {
        id: 'WEDNESDAY',
        label: 'Miércoles'
    },
    THURSDAY: {
        id: 'THURSDAY',
        label: 'Jueves'
    },
    FRIDAY: {
        id: 'FRIDAY',
        label: 'Viernes'
    }
}

export const MONTHS = {
    0: {
        id: 'JANUARY',
        label: 'Enero'
    },
    1: {
        id: 'FEBRUARY',
        label: 'Febrero'
    },
    2: {
        id: 'MARCH',
        label: 'Marzo'
    },
    3: {
        id: 'APRIL',
        label: 'Abril'
    },
    4: {
        id: 'MAY',
        label: 'Mayo'
    },
    5: {
        id: 'JUNE',
        label: 'Junio'
    },
    6: {
        id: 'JULY',
        label: 'Julio'
    },
    7: {
        id: 'AUGUST',
        label: 'Agosto'
    },
    8: {
        id: 'SEPTEMBER',
        label: 'Septiembre'
    },
    9: {
        id: 'OCTOBER',
        label: 'Octubre'
    },
    10: {
        id: 'NOVEMBER',
        label: 'Noviembre'
    },
    11: {
        id: 'DECEMBER',
        label: 'Diciembre'
    }
}