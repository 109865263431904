import {useLocation} from "react-router-dom";
import {IRoute} from "../constants/appRoutes";
import React from "react";
import {getLocationData} from "../utils/LocationUtils";

const useAppLocation = (): {activePath?: IRoute | null} => {
    const [activePath, handleActivePath] = React.useState<IRoute | null>(null)
    let {pathname} = useLocation();
    const validateActivePath = () => {
        const activePathData = getLocationData()
        handleActivePath(activePathData || null)
    }
    React.useEffect(() => {
        validateActivePath()
    }, [pathname])
    return {activePath}
}

export default useAppLocation