/*
const { initializeApp, applicationDefault, cert } = require('firebase-admin/app');
const { getFirestore, Timestamp, FieldValue } = require('firebase-admin/firestore');
 */

// Import the functions you need from the SDKs you need
import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/auth";


import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDHcdp718yPuts0JC0qdUuReWDBGKzm8JI",
    authDomain: "wm-admin-9780e.firebaseapp.com",
    databaseURL: "https://wm-admin-9780e-default-rtdb.firebaseio.com",
    projectId: "wm-admin-9780e",
    storageBucket: "wm-admin-9780e.appspot.com",
    messagingSenderId: "655082157561",
    appId: "1:655082157561:web:c6c6158fc444ac29f13928",
    measurementId: "G-BTK59K1CV0"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreApp = getFirestore()

const analytics = getAnalytics(firebaseApp);
