import * as React from 'react'
import useResponsive from '../../hooks/useResponsive'
import {ButtonComponent} from "../global/ButtonComponent";
import {useAppDispatch} from "../../store/storeHooks/hooks";
import {closeModal} from "../../store/slices/modalSlice";

interface IAsideLayoutFormComponent {
  children: React.ReactNode
  fullHeight?: boolean
  // eslint-disable-next-line
  successAction?: (() => void) | any;
  successActionText?: string
  cancelAction?: () => void
  closeOnCancel?: boolean
  cancelActionText?: string
  extraClass?: string
  title?: string | JSX.Element
  footerContent?: JSX.Element
}

export const ModalLayoutComponent = (props: IAsideLayoutFormComponent) => {
  const {
    children,
    title,
    cancelAction,
    fullHeight,
    cancelActionText,
    successAction,
    successActionText,
    footerContent,
    extraClass,
    closeOnCancel
  } = props
  const isResponsive = useResponsive()
  const windowHeight = window.innerHeight
  const marginBottomTop = 96
  const marginBottomTopResponsive = 80
  const heightFooter = 84
  const dispatch = useAppDispatch()
  const styles: { [name: string]: string | number } = {}
  const haveFooterContent = closeOnCancel || cancelAction || successAction || footerContent
  const getHeight = () => {
    let calcHeight =
      windowHeight - windowHeight * .1 -
      (isResponsive ? marginBottomTopResponsive : marginBottomTop)
    if (haveFooterContent) {
      calcHeight -= heightFooter
    }
    return calcHeight
  }
  const closeModalAction = () => {
    dispatch(closeModal())
  }
  const height = getHeight()
  styles.maxHeight = `${height}px`
  styles.height = fullHeight ? `${height}px` : 'auto'
  return (
    <div
      className={`modal-layout-component width-100 height-100 ${
        title && 'title-active'
      } ${haveFooterContent && 'bottom-actions'} ${extraClass || ''}`}
    >
      {title && (
        <div className="modal-layout-title">
          <h2
            className={
              'flex-middle flex-row height-100 m-none p-l p-r ' +
              'text-secondary'
            }
          >
            <span className="truncate">{title}</span>
          </h2>
        </div>
      )}
      <div className="modal-content-area" style={styles}>
        <div className="content-area block width-100 height-100">
          {children}
        </div>
      </div>
      {haveFooterContent && (
        <div className="modal-layout-footer flex-end">
          {footerContent || (
            <div className="flex-row flex-end p-l p-r">
              {(cancelAction || closeOnCancel) && (
                  <ButtonComponent
                      onClick={() => {
                        cancelAction ? cancelAction() : closeModalAction()
                      }}
                      buttonText={cancelActionText || 'Cancelar'}
                      buttonType="border-gray"
                      extraButtonClass="m-r-s"
                  />
              )}
              {successAction && (
                  <ButtonComponent
                      onClick={() => successAction()}
                      buttonText={successActionText || 'Guardar'}
                  />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
