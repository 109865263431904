import React, {FormEvent} from "react";
import {InputComponent} from "../components/global/InputComponent";
import {ButtonComponent} from "../components/global/ButtonComponent";
import {useAuthentication} from "../hooks/authenticationHooks";

const Login = () => {
    const [userData, handleUserData] = React.useState<{[name: string]: string}>({user: '', password: ''})
    const {login, user} = useAuthentication()
    const handleChange = (value: string, id: string) => {
        handleUserData({...userData, [id]: value})
    }
    const submitAction = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        login({user: userData.user, password: userData.password})
    }
    return <div className="m-auto p-t-lg login-section">
        <div className="login-container p-all border border-gray border-lighten-5 radius-default shadow-1">
            <form onSubmit={submitAction}>
                <div>
                    <h1 className="light">Cuenta WM</h1>
                    <p>Por favor completa la siguiente información para poder acceder a tu cuenta</p>
                </div>
                <div className="p-b p-t">
                    <InputComponent placeholder="ejm: email@wmrentacar.com" valueId="user" value={userData.user} onChangeValue={handleChange} label="Usuario de acceso" />
                </div>
                <div className="p-b">
                    <InputComponent placeholder="ejm: ****" valueId="password" value={userData.password} onChangeValue={handleChange} label="Contraseña" />
                </div>
                <div className="align-right p-b">
                    <ButtonComponent buttonText="Ingresar al sistema" formButton={true} />
                </div>
            </form>
        </div>
    </div>
}

export default Login