import React from 'react'

const useResponsive = () => {
  const [isResponsice, setIsResponsice] = React.useState(false)

  React.useEffect(() => {
    const updateResponsive = () => {
      // eslint-disable-next-line no-magic-numbers
      setIsResponsice(window.innerWidth < 1024)
    }
    window.addEventListener('resize', updateResponsive)
    updateResponsive()
    return () => window.removeEventListener('resize', updateResponsive)
  }, [isResponsice])

  return isResponsice
}

export default useResponsive
