import { LANGUAGES } from '../appEnums'

const getLabels = (labelsFileName: string, language?: string) => {
  const getDefault = () => {
    try {
      return require(`./es_EC/${labelsFileName}`)
    } catch (error) {
      return null
    }
  }
  let dictionaryFile = null
  switch (language) {
    case LANGUAGES.en_US.id:
      try {
        // eslint-disable-next-line
        dictionaryFile = require(`./en_US/${labelsFileName}`).default;
      } catch (e) {
        dictionaryFile = null
      }
      return dictionaryFile || getDefault()?.default || {}
    default:
      return getDefault()?.default || {}
  }
}

export default getLabels
