import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addBodyClass, removeBodyClass } from '../../utils/BodyClassUtil'
import MODALS from '../../modals'

export type IModalSizes =
  | 'FULL-WIDTH'
  | 'EXTRA_SMALL'
  | 'SMALL'
  | 'AUTO'
  | 'MEDIUM'

export interface IModalContentData {
  component: keyof typeof MODALS
  modalSize?: IModalSizes
  inverse?: boolean
  directProps?: any
  hideCloseButton?: boolean
}

export interface IModalReducer {
  isOpen: boolean
  contentData: IModalContentData | null
}

const initialState: IModalReducer = {
  contentData: null,
  isOpen: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      removeBodyClass('open-modal')
      state.isOpen = false
    },
    setModalComponent: (state, action: PayloadAction<IModalContentData>) => {
      addBodyClass('open-modal')
      state.contentData = action.payload
      state.isOpen = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { closeModal, setModalComponent } = modalSlice.actions

export default modalSlice.reducer
