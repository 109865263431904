import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AlertTypes = 'success' | 'danger' | 'warning' | 'info'

export interface IAlertsObjectType {
  value: string
  title?: string
  type: AlertTypes
}

export interface IAlertsReducer {
  alertList: Array<IAlertsObjectType>
}

const initialState: IAlertsReducer = {
  alertList: [],
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlertItemArray: (
      state,
      action: PayloadAction<Array<IAlertsObjectType>>
    ) => {
      state.alertList = [...state.alertList, ...action.payload]
    },
    addAlertItem: (state, action: PayloadAction<IAlertsObjectType>) => {
      state.alertList = [...state.alertList, action.payload]
    },
    removeAlertItemArray: (
      state,
      action: PayloadAction<Array<IAlertsObjectType>>
    ) => {
      state.alertList = state.alertList.filter((item) => {
        let ifExist = false
        action.payload.map((itemList) => {
          if (item.value === itemList.value) {
            ifExist = true
          }
        })
        if (!ifExist) {
          return item
        }
      })
    },
    removeAlertItem: (state, action: PayloadAction<IAlertsObjectType>) => {
      state.alertList = state.alertList.filter(
        (item) => item.value !== action.payload.value
      )
    },
  },
})

export const {
  addAlertItem,
  addAlertItemArray,
  removeAlertItem,
  removeAlertItemArray,
} = alertsSlice.actions

export default alertsSlice.reducer
