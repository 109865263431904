import {useAppDispatch, useAppSelector} from "../store/storeHooks/hooks";
import {setModalComponent} from "../store/slices/modalSlice";
import React from "react";
import {SvgImport} from "../utils/ImageUtils";
import editIcon from "../assets/images/edit.svg";
import documentIcon from "../assets/images/document.svg";
import {ButtonComponent} from "../components/global/ButtonComponent";
import addIcon from "../assets/images/add.svg";
import {TableComponent} from "../components/global/TableComponent";
import {ILocalDocuments} from "../store/slices/documentsSlice";
import {TooltipComponent} from "../components/global/TooltipComponent";
import {useNavigate} from "react-router-dom";
import {APP_ROUTES} from "../constants/appRoutes";
import {snapShotDocumentsSubscription} from "../firebase/documentsFirebase";
import {formatDateString} from "../utils/DatesUtils";
import {CITIES_CONSTANTS} from "../constants/citiesConstants";
import {SelectComponent} from "../components/global/SelectComponent";
import DateComponent from "../components/global/DateComponent";

const Documents = () => {
    const [cityFilter, handleCityFilter] = React.useState('')
    const [dateFilter, handleDateFilter] = React.useState('')
    const dispatch = useAppDispatch()
    const documents = useAppSelector(state => state.documents.documentsList)
    const navigation = useNavigate()
    const documentModal = (document?: ILocalDocuments) => {
        dispatch(setModalComponent({
            component: 'DOCUMENT',
            modalSize: 'SMALL',
            directProps: {document: document || null}
        }))
    }
    const getFilterContent = () => {
        let filterDocuments = documents
        if (cityFilter || dateFilter) {
            filterDocuments = filterDocuments.filter((document) => {
                let isValid = true
                if (cityFilter && document.city !== cityFilter) {
                    isValid = false
                }
                if (dateFilter && dateFilter !== document.contractDate) {
                    isValid = false
                }
                return isValid
            })
        }
        return filterDocuments
    }
    const viewDocument = (documentId: string) => {
        navigation(`${APP_ROUTES.DOCUMENT_PREVIEW.path}?document=${documentId}`)
    }
    const labels: any = [
        {label: 'FECHA DEL CONTRATO', id: 'date', width: 20, typeWidth: '%'},
        {label: 'USUARIO', id: 'user', width: 25, typeWidth: '%'},
        {label: 'CONTRATADO EN', id: 'city', width: 15, typeWidth: '%'},
        {label: 'USUARIO CI/PASAPORTE', id: 'document', width: 15, typeWidth: '%'},
        {label: 'USUARIO TELÉFONO', id: 'phone', width: 15, typeWidth: '%'},
        {label: '', id: 'action', width: 10, typeWidth: '%'},
    ]

    const tableColumns: any = getFilterContent().map(item => {
        return {
            id: item.id,
            columns: [
                {id: 'date', value: <strong className="text-gray">{item.contractDate}</strong>, type: 'custom'},
                {id: 'user', value: <strong className="text-gray">{item.user.name} {item.user.lastname}</strong>, type: 'custom'},
                {id: 'document', value: item.user.document, type: 'string'},
                {id: 'city', value: item.city, type: 'string'},
                {id: 'phone', value: item.user.phone, type: 'string'},
                {
                    id: 'action',
                    value: <div className="flex-row flex-end">
                        <TooltipComponent content={<small className="block align-center">Generar Documento</small>} maxWidth={120} position="bottomRight">
                            <a
                                onClick={() => viewDocument(item.id)}
                                className="icon-24x m-l-s m-r-s radius-small bg-gray bg-lighten-6 flex-column flex-middle flex-center icon-flex"
                            >
                                <SvgImport icon={documentIcon} className="icon-16x icon-flex fill-success flex-row" />
                            </a>
                        </TooltipComponent>
                        <TooltipComponent content={<small className="block align-center">Editar/Detalles</small>} maxWidth={100} position="bottomRight">
                            <a
                                onClick={() => documentModal(item)}
                                className="icon-24x radius-small bg-gray bg-lighten-6 flex-column flex-middle flex-center icon-flex"
                            >
                                <SvgImport icon={editIcon} className="icon-16x icon-flex fill-success flex-row" />
                            </a>
                        </TooltipComponent>
                    </div>,
                    type: 'custom'
                },
            ]
        }
    })

    React.useEffect(() => {
        const snapShotSubscription = snapShotDocumentsSubscription(dispatch)
        return () => snapShotSubscription()
    }, [])

    return <div>
        <div className="flex-row">
            <div className="p-r-s p-b-s" style={{minWidth: 140}}>
                <SelectComponent
                    valueId="city"
                    value={cityFilter}
                    onChangeValue={(id => handleCityFilter(id as string))}
                    label="Filtrar por Ciudad"
                    placeholder="Todas"
                    options={[
                        ...Object.values(CITIES_CONSTANTS).map((city) => ({id: city.id, label: city.label})),
                        {
                            id: '',
                            label: 'Todas'
                        }
                    ]}
                />
            </div>
            <div className="p-r-s p-b-s">
                <div style={{minWidth: 190}}>
                    <DateComponent
                        label="Fecha del contrato"
                        valueId="contractDate"
                        value={dateFilter}
                        onChange={handleDateFilter}
                        clearAction={dateFilter ? () => {handleDateFilter('')} : undefined}
                    />
                </div>
            </div>
            <div className="flex-1 align-right">
                <div className="inline-block p-t">
                    <ButtonComponent
                        buttonText="Crear documento"
                        smallButton={true}
                        iconButton={<SvgImport icon={addIcon} className="icon-16x m-l fill-white" />}
                        onClick={() => documentModal()}
                    />
                </div>
            </div>
        </div>
        <div className="width-100 p-t-lg">
            <TableComponent tableRows={tableColumns} labelProps={labels} responsiveStyle="CARD" />
        </div>
    </div>
}

export default Documents