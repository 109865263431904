import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import './scss/index.scss';
import App from './App';

function AppWithCallbackAfterRender() {
    return <BrowserRouter>
        <App />
    </BrowserRouter>
}

ReactDOM.render(
    <React.StrictMode>
        <AppWithCallbackAfterRender />
    </React.StrictMode>,
    document.getElementById('root')
);