import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUser {
  name: string
  lastname: string
  document: string
  email: string
  phone: string
  documents?: string[]
}

export interface IUserLocalUser extends IUser{
  id: string
}

export interface IUsersReducer {
  userList: IUserLocalUser[]
}

const initialState: IUsersReducer = {
  userList: [],
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<Array<IUserLocalUser>>) => {
      state.userList = action.payload || []
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUsers } = usersSlice.actions

export default usersSlice.reducer
