import * as React from 'react'
import { CSSTransition } from 'react-transition-group'

import closeIcons from '../../assets/images/close.svg'
import {SvgImport} from "../../utils/ImageUtils";
import MODALS from "../../modals";
import {closeModal} from "../../store/slices/modalSlice";
import {useAppDispatch, useAppSelector} from "../../store/storeHooks/hooks";

export const ModalContent = () => {
  const contentData = useAppSelector((state) => state.modal.contentData)
  const dispatch = useAppDispatch()
  const validateScape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close()
    }
  }
  const close = () => {
    dispatch(closeModal())
  }
  React.useEffect(() => {
    window.addEventListener('keyup', validateScape)
    return () => {
      window.removeEventListener('keyup', validateScape)
    }
  }, [])

  const getActiveComponent = (): React.ComponentType<{ directProps?: any }> | null => {
    if (contentData?.component && MODALS[contentData?.component]) {
      return MODALS[contentData?.component].component
    }
    return null
  }

  const ActiveComponent = getActiveComponent()
  return (
    <div
      className={`modal-view flex-center flex-column flex-middle ${
        contentData?.inverse ? 'inverse-modal' : 'primary-modal'
      }`}
    >
      <div className="close-area" onClick={() => close()} />
      <div
        className={`${
          contentData?.modalSize || 'AUTO'
        } flex-column modal-container`}
      >
        {!contentData?.hideCloseButton && (
          <div className="modal-header div-top flex-row flex-end m-l m-r">
            <a className="flex-row flex-end" onClick={() => close()}>
              <SvgImport
                icon={closeIcons}
                className={`${contentData?.inverse && 'fill-white'} icon-24x`}
              />
            </a>
          </div>
        )}
        <div className="modal-body flex-row div-top flex-1">
          {ActiveComponent && (
            <ActiveComponent directProps={contentData?.directProps} />
          )}
        </div>
      </div>
    </div>
  )
}

export const ModalView = () => {
  const isOpen = useAppSelector((state) => state.modal.isOpen)
  return (
    <CSSTransition
      in={isOpen}
      timeout={200}
      classNames="modal-transition"
      unmountOnExit={true}
    >
      {(__state) => <ModalContent />}
    </CSSTransition>
  )
}
