import {doc, onSnapshot} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import {IDocumentStore} from "../store/slices/documentsSlice";

const firestoreDocumentReference = (id: string) => doc(firestoreApp, `documents/${id}`)

export const snapShotDocumentSubscription = (documentId: string, onLoadAction: (document: IDocumentStore) => void) => onSnapshot(
    firestoreDocumentReference(documentId),
    (docSnapshot) => {
    const documentsResponse = docSnapshot?.data() as IDocumentStore
    onLoadAction(documentsResponse)
})
