export const sendMessage = (url: string, data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if (url && data) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `https://admin.alquilerdeautos.ec/form/${url}.php`, true);
        xhr.send(formData);
        xhr.onload = function() {
            var data = JSON.parse(this.responseText);
            console.log(this.responseText)
            console.log(data)
        }
    }
}