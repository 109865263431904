export interface INavSection {
  name: string
  title: string
  path: string
  id: string
}

export type ILanguage = 'es_EC' | 'en_US'

interface ILanguageType {
  id: ILanguage
  name: ILanguage
}

interface ILanguagesEnum {
  es_EC: ILanguageType
  en_US: ILanguageType
}

export const LANGUAGES: ILanguagesEnum = {
  es_EC: {
    id: 'es_EC',
    name: 'es_EC',
  },
  en_US: {
    id: 'en_US',
    name: 'en_US',
  },
}