import { DateRange } from 'react-date-range';
import React from "react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import calendarIcon from '../../assets/images/calendar.svg';

export interface IDate {startDate: Date | undefined; endDate: Date | undefined; key: string}

interface IDateRangeProps {
    valueId: string
    label: string
    selectDate:  IDate | undefined
    changeSelectDate: (date: IDate, key: string) => void
    error?: string
}

const DateRangeComponent = (props: IDateRangeProps) => {
    const [isOpen, changeIsOpen] = React.useState(false);
    const [state, setState] = React.useState<{startDate: Date | undefined; endDate: Date | undefined; key: string}>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    const stringToDate = (dates: {startDate: string; endDate: string; key: string}) => {
        return {
            ...dates,
            startDate: new Date(`${dates.startDate}T12:00:00Z`),
            endDate: new Date(`${dates.endDate}T12:00:00Z`)
        }
    }

    const formatStringDate = (dateValue: any) => {
        const month = dateValue.getMonth() + 1
        const day = dateValue.getDate()
        return `${dateValue.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
    }

    const dateToString = (dates: {startDate: Date; endDate: Date; key: string}) => {
        return {
            ...dates,
            startDate: formatStringDate(dates.startDate),
            endDate: formatStringDate(dates.endDate)
        }
    }

    const closeRange = () => {
        changeIsOpen(false)
        setState(stringToDate(props.selectDate as any));
    }

    const onSelectData = (item: any) => {
        setState(item.selection)
    }

    return <div className="date-range">
        <div onClick={() => {
                if (!isOpen) {
                    changeIsOpen(true)
                    if (props.selectDate) {
                        setState(stringToDate(props.selectDate as any) as any);
                    }
                } else {
                    closeRange()
                }
             }}
             className="date-range-button flex-column p-t-s p-b-s p-l p-r radius-small cursor-pointer"
        >
            <div className="flex-row label text-uppercase">
                <span className="date-range-label light">{props.label || 'FECHAS'}</span>
            </div>
            <div className="flex-row width-100">
                <div className="flex-column flex-1 date-range-value">
                    {props.selectDate?.startDate && props.selectDate?.endDate ? `desde ${props.selectDate.startDate} - hasta ${props.selectDate.endDate}` : 'Selecciona las fechas'}
                </div>
                <div className="flex-column">
                    <img src={calendarIcon} className="icon-16x"/>
                </div>
            </div>
            {props.error && <span className="error-message">{props.error}</span>}
        </div>
        {isOpen &&
            <div className="range-content">
                <div className="close-bg" onClick={closeRange} />
                <div className="bg-white picker-area">
                    <DateRange
                        minDate={new Date()}
                        editableDateInputs={true}
                        onChange={onSelectData}
                        moveRangeOnFirstSelection={false}
                        ranges={state ? [state] : [
                            {
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection'
                            }
                        ]}
                    />
                    <div className="align-center">
                        <a
                            onClick={() => {
                                props.changeSelectDate(dateToString(state as any) as any, props.valueId)
                                changeIsOpen(false)
                            }}
                           className="text-primary p-all block">
                            Seleccionar estas fechas
                        </a>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default DateRangeComponent;
