import UserModal from "./UserModal";
import DocumentModal from "./DocumentModal";

const MODALS = {
  USER: {
    id: 'USER',
    component: UserModal,
  },
  DOCUMENT: {
    id: 'DOCUMENT',
    component: DocumentModal,
  },
}

export default MODALS
