import homeIcon from '../assets/images/home.svg'
import usersIcon from '../assets/images/user.svg'
import documentsIcon from '../assets/images/documents.svg'
import logoutIcon from '../assets/images/logout.svg'
import Home from "../sections/Home";
import Users from "../sections/Users";
import Documents from "../sections/Documents";
import Login from "../sections/Login";
import DocumentsPreview from "../sections/DocumentsPreview";

export interface IRoute {
    action?: string,
    path: string,
    pathWithParams?: string,
    icon?: string,
    name: string,
    title: string,
    hideNav?: boolean,
    component?: () => JSX.Element
}

interface IRoutes {
    [name: string]: IRoute
}

export const APP_ROUTES: IRoutes = {
    HOME: {
        path: '/home',
        icon: homeIcon,
        name: 'Inicio',
        component: Home,
        title: 'Sistema de gestión de alquileres WM'
    },
    USERS: {
        path: '/users',
        icon: usersIcon,
        name: 'Usuarios',
        component: Users,
        title: 'Gestión de usuario'
    },
    DOCUMENTS: {
        path: '/documents',
        icon: documentsIcon,
        name: 'Documentos',
        title: 'Gestión de documentos',
        component: Documents
    },
    LOGIN: {
        path: '/',
        name: 'Inicio de Sesión',
        title: 'Acceso a la cuenta',
        component: Login,
        hideNav: true
    },
    DOCUMENT_PREVIEW: {
        path: '/document-preview',
        name: 'Detalle del contrato',
        title: 'Detalle del contrato',
        component: DocumentsPreview,
        hideNav: true
    },
}

export const APP_NAVIGATION = [
    APP_ROUTES.HOME,
    APP_ROUTES.USERS,
    APP_ROUTES.DOCUMENTS,
    {
        path: '/',
        action: 'logout',
        name: 'Cerrar Sesión',
        title: 'Cerrar Sesión',
        icon: logoutIcon
    }
]

export const PRIVATE_ROUTES = [APP_ROUTES.HOME.path, APP_ROUTES.USERS.path, APP_ROUTES.DOCUMENTS.path, APP_ROUTES.DOCUMENT_PREVIEW.path]
export const PUBLIC_ROUTES = [APP_ROUTES.LOGIN.path, APP_ROUTES.DOCUMENT_PREVIEW.path]