import React from 'react';
import { Routes, Route } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
import {Provider} from "react-redux";

import Header from "./components/layout/Header";
import AsideNavigation from "./components/layout/AsideNavigation";
import {APP_ROUTES} from "./constants/appRoutes";
import useAppLocation from "./hooks/useAppLocation";
import {addBodyClass, removeBodyClass} from "./utils/BodyClassUtil";
import {persistor, store} from "./store/store";
import {ModalView} from "./components/modals/ModalView";
import LoaderView from "./components/layout/LoaderView";
import AlertsView from "./components/layout/AlertsView";

function App() {
  const {activePath} = useAppLocation()
  React.useEffect(() => {
      if (activePath?.hideNav) {
          addBodyClass('no-nav')
      } else {
          removeBodyClass('no-nav')
      }
  }, [activePath?.path, activePath?.pathWithParams])
  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <div className="App">
                <Header/>
                {!activePath?.hideNav &&
                    <AsideNavigation />
                }
                <div className="p-t">
                    <Routes>
                        {Object.entries(APP_ROUTES).map(([key, rt]) => {
                            const RouteComponent = rt.component as any
                            return <Route key={key} path={rt.path} element={<RouteComponent />} />
                        })}
                    </Routes>
                </div>
                <ModalView/>
                <LoaderView />
                <AlertsView />
            </div>
          </PersistGate>
      </Provider>
  );
}

export default App;
