import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import accountSlice from './slices/accountSlice'
import modalSlice from './slices/modalSlice'
import loaderSlice from './slices/loaderSlice'
import alertsSlice from './slices/alertsSlice'
import authSlice from './slices/authSlice'
import usersSlice from './slices/usersSlice'
import documentsSlice from './slices/documentsSlice'
import {rootApi} from "./api/rootApi";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['account'],
}

const rootReducers = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  account: accountSlice,
  authentication: authSlice,
  modal: modalSlice,
  loader: loaderSlice,
  alerts: alertsSlice,
  users: usersSlice,
  documents: documentsSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
})

export let persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch