import React from "react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {Calendar} from 'react-date-range';
import calendarIcon from "../../assets/images/calendar.svg";


interface IDateProps {
    valueId: string
    value:  string
    label?:  string
    error?:  string
    clearAction?: () => void | null
    onChange: (date: string, key: string) => void
}

const DateComponent = (props: IDateProps) => {
    const [isOpen, changeIsOpen] = React.useState(false);

    const closeRange = () => {
        changeIsOpen(false)
    }
    const onSelectData = (item: any) => {
        const newDate = formatDate(item)
        changeIsOpen(false)
        props.onChange(newDate, props.valueId)
    }
    const selectedDate = () => {
        if (props.value) {
            const selected = new Date(typeof props.value === 'string' ? `${props.value}T12:00:00Z` : props.value)
            return isNaN(selected.getDate()) ? undefined : selected
        }
        return undefined
    }
    const formatDate = (dateValue: any) => {
        if (!dateValue) {
            return ''
        }
        const date = new Date(typeof dateValue === 'string' ? `${dateValue}T12:00:00Z` : dateValue)
        if (!date) {
            return ''
        }
        const month = date.getMonth() + 1
        const day = date.getDate()
        return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
    }

    return <div className="date-range">
        <div onClick={() => {
            if (!isOpen) {
                changeIsOpen(true)
            } else {
                closeRange()
            }
        }}
             className="date-range-button flex-column bg-gray bg-lighten-1 p-t-s p-b-s p-l p-r radius-small cursor-pointer"
        >
            <div className="flex-row label text-uppercase">
                <span className="date-range-label light">{props.label || 'FECHA'}</span>
            </div>
            <div className="flex-row width-100">
                <div className="flex-column flex-1 date-range-value">
                    {props.value ? formatDate(props.value) : 'Selecciona una fecha'}
                </div>
                <div className="flex-column">
                    <img src={calendarIcon} className="icon-16x"/>
                </div>
            </div>
            {props.error && <span className="error-message">{props.error}</span>}
        </div>
        {isOpen &&
            <div className="range-content">
                <div className="close-bg" onClick={closeRange} />
                <div className="bg-white picker-area">
                    <Calendar onChange={item => onSelectData(item)} date={selectedDate()}/>
                    {props.clearAction &&
                        <div className="p-all center-align">
                            <a
                                className="block text-primary align-center text-uppercase size-small cursor-pointer"
                                onClick={() => {
                                    if (props.clearAction) {
                                        props.clearAction()
                                        changeIsOpen(false)
                                    }
                                  }
                                }
                            >
                                Borrar selección
                            </a>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}

export default DateComponent;
