import rentACar from "../assets/images/wm_rent_a_car_alquile_de_autos.svg";
import React from "react";

const Home = () => {
    return <div className="p-t-lg">
        <div className="width-100 p-lg-all align-center">
            <img className="brand" width={300} src={rentACar} alt=""/>
        </div>
        <div className="p-lg-all size-4 light line-height-default text-gray text-lighten-2 m-auto" style={{maxWidth: 600}}>
            Bienvenido al sistema de gestión de documentos <strong>WM Rent A Car</strong>, en el mismo se podrá gestionar los usuarios
            y documentos de contratos para sus clientes
        </div>
    </div>
}

export default Home