import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SIGNERS } from '../../constants/signerConstants'

export interface IUserAccount {
  email: string
  id: string
  name?: string
}

export interface IActiveUserAccount {
  token: string
  user: IUserAccount
}

export interface IAccountReducer {
  activeUserAccount: IActiveUserAccount
  userAccount: {
    signers: Array<SIGNERS>
  }
}

const defaultUser: IUserAccount = {
  email: '',
  id: '',
  name: '',
}

export const defaultAccount: IActiveUserAccount = {
  token: '',
  user: { ...defaultUser },
}

const initialState: IAccountReducer = {
  activeUserAccount: { ...defaultAccount },
  userAccount: {
    signers: [],
  },
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<IActiveUserAccount>) => {
      state.activeUserAccount = action.payload
    },
    setUserInformation: (state, action: PayloadAction<IActiveUserAccount>) => {
      state.activeUserAccount.user = action.payload.user
    },
    logout: (state) => {
      state.activeUserAccount = defaultAccount
    },
    setSubsidiaries: (state, action: PayloadAction<{signers: Array<SIGNERS>}>) => {
      console.log('action.payload:::::', action.payload)
      state.userAccount = {
        ...action.payload,
      }
    }
  },
})

export const { setActiveUser, logout, setUserInformation, setSubsidiaries } =
  accountSlice.actions

export default accountSlice.reducer
